import React from 'react'

import ImageListItem from '@mui/material/ImageListItem'
import { Stack, Typography } from '@mui/material'

import { CustomImageListItemProps } from './Interfaces'

function CustomImageListItem({ item, selected, toggleSelection, withLink }: CustomImageListItemProps) {
  return (
    <ImageListItem style={{border: '1px solid gray'}}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}
      >
        <Typography>Count: {item?.phash_count}</Typography>
        {(withLink && item?.listing_id) && (
          <a
            href={`http://spiders.espressoresearch.com/_admin/sourceestate/${item.listing_id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {item.listing_id}
          </a>
        )}
      </Stack>
      <img
        src={item.url}
        srcSet={item.url}
        alt=""
        loading="lazy"
        onClick={toggleSelection}
        style={{
          objectFit: 'none',
          cursor: 'pointer',
          border: selected ? '2px solid blue' : 'none',
        }}
      />
    </ImageListItem>
  );
}

export default CustomImageListItem;
