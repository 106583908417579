import React from 'react'
import { Alert, AlertTitle, Box, Container, Grid, Typography } from '@mui/material'
import { useHistory } from 'react-router-dom'


import API from '../services/api'
import useMobileService from '../utils/mobileService'

import errorImage from '../assets/error.svg'

const MS_10_MIN = 10 * 60 * 1000;

function ErrorPage() {
  const isMobile = useMobileService()
  const history = useHistory()

  React.useEffect(() => {
    const interval = setInterval(() => {
      API.get('/health-check/').then(() => {
        history.push('/', { shallow: true })
      }).catch((error) => {
        console.error('Service not available', error)
      })
    }, MS_10_MIN)

    return () => clearInterval(interval)
  }, [history])

  return (
    <Box className="ErrorPage">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} order={{ md: 2 }}>
            <Typography
              variant="h3"
              component="h1"
              align={isMobile ? 'center' : undefined}
              sx={{ fontWeight: 'bold' }}
            >503</Typography>
            <Alert severity="error">
              <AlertTitle>The service is temporarily unavailable</AlertTitle>
              Our IT folks are still at Ironman. They will be back & repair service.
            </Alert>
          </Grid>
          <Grid item xs={12} md={6} order={{ md: 1 }}>
            <img
              alt="CASAFARI - ERROR"
              src={errorImage}
            />
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default ErrorPage
