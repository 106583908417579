import { useEffect } from 'react'
import { connect } from 'react-redux'
import { shallow } from 'zustand/shallow'

import { Alert, AlertTitle, Box, LinearProgress, Stack } from '@mui/material'

import Item from '../components/estate/Item'
import VotingActions from '../redux/votingRedux'
import { UsePerformanceStore } from '../stores/UsePerformanceStore'

import DuplicatesPopUp from '../components/estate/DuplicatesPopUp'
import { iEstate } from '../components/estate/Candidate'


interface iProps {
  vote: any
  target: iEstate
  candidates: iEstate[]
  fetching: boolean

  candidate_duplicates: number[]

  skip: () => void
  goNext: () => void
}

function VotingPage({
  vote, target, candidates, candidate_duplicates, fetching, goNext,
}: iProps) {
  const { loadPerformance } = UsePerformanceStore((state) => state, shallow)
  const drawerWidth = 200

  useEffect(() => {
    goNext()
  }, [goNext])

  // for <Statistic ... /> component
  useEffect(() => vote?.user && loadPerformance(vote.user), [vote.user, loadPerformance])

  if (!vote || !target || !candidates?.length) {
    if (fetching) {
      return <LinearProgress />
    }
    return <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="error">
        <AlertTitle>
          {vote ? `Deprecated Vote (#${vote.id}).` : `There are no tasks for voting, please try again later.`}
        </AlertTitle>
      </Alert>
    </Stack>
  }

  return (
    <Box sx={{
      pl: candidate_duplicates.length ? `${drawerWidth}px` : 0,
      width: '100%',
    }} >
      <DuplicatesPopUp width={drawerWidth} />
      <Item
        isSource={true}
        estate={target}
      />
      {fetching && <LinearProgress />}
      {candidates.map((candidate, index) => (
        <Item
          key={`${index}-${candidate.id}`}
          isSource={false}
          estate={candidate}
          isDuplicate={candidate_duplicates.includes(candidate.id)}
        />
      ))}
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    candidate_duplicates: state.voting.candidate_duplicates,

    vote: state.voting.vote,
    target: state.voting.target,
    candidates: state.voting.candidates,
    fetching: state.voting.fetching,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    skip: () => dispatch(VotingActions.skip()),
    goNext: () => dispatch(VotingActions.goNext()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VotingPage)
