import ScrollContainer from 'react-indiana-drag-scroll'
import { ImageList, ImageListItem, Skeleton } from '@mui/material'


interface iProps {
  items?: string[]
}

const DEFAULT_SCELETON_ITEMS = [1, 2, 3, 4, 5];

export default function Images(props: iProps) {
  const items = props?.items || DEFAULT_SCELETON_ITEMS;

  return (
    <ScrollContainer className="scroll-container" vertical={false} hideScrollbars={false}>
      <ImageList
        variant="woven"
        cols={items.length}
        rowHeight={180}
        sx={{ overflow: 'unset', width: 'fit-content', m: 0 }}
      >
        {items.map((item, index) => {
          if (typeof item == 'number') return <Skeleton key={index} variant="rectangular" width={320} height={180} />

          return (
            <ImageListItem key={index} style={{ width: 320, height: '100%' }}>
              <img
                src={item}
                alt=""
                loading="lazy"
                style={{
                  lineHeight: '100%',
                  textAlign: 'center',
                }}
              />
            </ImageListItem>
          )
        })}
      </ImageList>
    </ScrollContainer>
  )
}
