import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Box, Typography } from '@mui/material'
import {
  DataGrid,
  getGridDateOperators,
  getGridNumericOperators,
  GridColDef,
  GridFilterModel,
  GridSortModel
} from '@mui/x-data-grid'
import Actions, { iTransaction } from '../../redux/transactionsRedux'
import { DASH, PAGE_SIZES, SORT_BY_NEWEST } from '../../utils/constants'
import { currencyFormatter, currencyGetter } from '../../utils/formatters'

interface iProps {
  user: any

  count: number
  list: iTransaction[]
  fetching: boolean

  load: (page: number, pageSize: number, sort: any, filter: any) => void
}

interface iState {
  page: number
  pageSize: number
  sort: GridSortModel
  filter: GridFilterModel
}

const columns: GridColDef[] = [
  {
    field: 'id',
    headerName: 'ID',
    type: 'number',
    minWidth: 150,
    hide: true,
    filterOperators: getGridNumericOperators().filter(
      (operator) => ['=', '>', '<'].includes(operator.value),
    ),
  },
  {
    field: 'created_at',
    headerName: 'Date',
    type: 'date',
    minWidth: 250,
    filterOperators: getGridDateOperators().filter(
      (operator) => ['is', 'after', 'before'].includes(operator.value),
    ),
    valueFormatter: (params) => {
      if (params.value) {
        let date = new Date(Date.parse(params.value as string))
        return date.toLocaleDateString() + ' ' + date.toLocaleTimeString()
      }
    },
  },
  {
    field: 'type',
    headerName: 'Action',
    type: 'singleSelect',
    minWidth: 200,
    valueOptions: [1, 2, 3, 4],
    valueFormatter: ({ value }) => {
      // @ts-ignore
      return ['UNKNOWN', 'Voting', 'Manage dispute', 'Dispute', 'Payout'][value]
    }
  },
  {
    field: 'amount',
    headerName: 'Amount',
    type: 'number',
    minWidth: 200,
    filterOperators: getGridNumericOperators().filter(
      (operator) => ['=', '>', '<'].includes(operator.value),
    ),
    valueGetter: (params) => currencyGetter(params.row.compensation),
    valueFormatter: (params) => currencyFormatter(params.value),
  },
  {
    field: 'count_total',
    headerName: 'Total',
    type: 'number',
    minWidth: 150,
    filterable: false,
    valueGetter: (params) => {
      const match_count_total = params.row.match_count_total
      const total = params.value > match_count_total ? params.value : match_count_total
      return total || DASH
    },
  },
  // {
  //   field: 'match_count',
  //   headerName: 'Paid votes',
  //   type: 'number',
  //   minWidth: 150,
  //   filterable: false,
  //   valueFormatter: (params) => params.value || DASH,
  // },
]

function Transactions({
  user, count, list, fetching, load,
}: iProps) {
  const [values, setValues] = React.useState<iState>({
    page: 0,
    pageSize: 20,
    sort: [SORT_BY_NEWEST],
    filter: {
      items: [],
    },
  })

  useEffect(() => {
    // Because manager has access to all transactions, not only own
    const filter = {
      ...values.filter,
      items: [
        ...values.filter?.items,
        {
          columnField: 'user_id',
          operatorValue: '=',
          value: user.id,
        },
      ],
    }

    load(values.page, values.pageSize, values.sort, filter)
  }, [values, user.id, load])

  const handleSortChange = (newSort: GridSortModel) => {
    setValues({ ...values, 'sort': newSort })
  }

  const onFilterChange = (newFilter: GridFilterModel) => {
    setValues({ ...values, 'filter': newFilter })
  }

  return (
    <React.Fragment>
      <Typography component="h2" variant="h6" color="primary">
        Recent Transactions
      </Typography>
      <DataGrid
        rows={list}
        columns={columns}
        autoHeight={true}
        // pagination
        rowsPerPageOptions={PAGE_SIZES}
        rowCount={count}
        page={values.page}
        pageSize={values.pageSize}
        paginationMode="server"
        onPageChange={(newPage) => {
          setValues({ ...values, 'page': newPage })
        }}
        onPageSizeChange={(newPageSize) => {
          setValues({ ...values, 'page': 0 })
          setValues({ ...values, 'pageSize': newPageSize })
        }}
        // sorting
        sortingMode="server"
        sortModel={values.sort}
        onSortModelChange={handleSortChange}
        // filtering
        filterMode="server"
        onFilterModelChange={onFilterChange}
        loading={fetching}
        // styling
        components={{
          NoRowsOverlay: () => (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              mt: 8,
            }}>
              <Typography color="error">Any transactions yet</Typography>
            </Box>
          ),
        }}
      />
    </React.Fragment>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,

    count: state.transactions.count,
    list: state.transactions.list,
    fetching: state.transactions.fetching,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    load: (page: number, pageSize: number, sort: any, filter: any) => dispatch(Actions.loadTransactions(page, pageSize, sort, filter)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)
