import React, { useEffect } from 'react'
import { connect, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { shallow } from 'zustand/shallow'

import { Alert, AlertTitle, Box, Button, ButtonGroup, Stack, TextField } from '@mui/material'
import { GridFilterModel } from '@mui/x-data-grid'
import { Check as IconCheck, Close as IconCross } from '@mui/icons-material'

import DisputeActions, {
  iDispute,
  STATUS_CONFIRMED,
  STATUS_IN_PROGRESS,
  STATUS_NEW,
  STATUS_REJECTED,
  STATUS_DEPRECATED,
} from '../redux/disputeRedux'
import VotingActions from '../redux/votingRedux'
import Candidate, { iEstate } from '../components/estate/Candidate'
import { isEmpty } from '../utils/functions'
import { getIsReferenceVote, getParentCheckId, getPermissions } from '../selectors'
import { UseEvaluationRequestStore } from '../stores/UseEvaluationRequestStore'

interface iProps {
  fetchingD: boolean
  count: number
  disputes: iDispute[]

  fetchingV: boolean
  target: iEstate
  candidates: iEstate[]
  confirmed_duplicates: number[]

  createDispute: (data: iDispute) => void
  loadDisputes: (filter: GridFilterModel) => void
  loadVoteById: (id: number) => void
}

interface iState {
  [key: number]: string
}

function DisputePage(props: iProps) {
  const { fetchingD, fetchingV, disputes, target, candidates, confirmed_duplicates } = props
  const task_id = useSelector(getParentCheckId)
  const is_reference_vote = useSelector(getIsReferenceVote)
  const { allow_manage, allow_dispute } = useSelector(getPermissions)
  const [comments, setComment] = React.useState<iState>({})
  // @ts-ignore
  const { vote_id } = useParams()
  const filter: GridFilterModel = {
    items: [
      { columnField: 'vote', id: 27309, operatorValue: '=', value: vote_id }
    ],
  }

  const { updateEvaluationRequest } = UseEvaluationRequestStore((state) => ({
    updateEvaluationRequest: state.update,
  }), shallow)

  useEffect(() => {
    props.loadDisputes(filter)
    props.loadVoteById(vote_id)
  }, [])

  const openDisputes = () => {
    let candidate_id: number = 0
    // create all disputes by candidates
    Object.keys(comments).map(async index => {
      candidate_id = parseInt(index)
      const data: iDispute = {
        status: STATUS_NEW,
        vote: vote_id,
        candidate_id,
        comment: comments[candidate_id],
      }
      await props.createDispute(data)
    })

    // reload all disputes
    setComment({})
    props.loadDisputes(filter)

    // scroll to top
    window.scrollTo(0, 0)
  }

  const getDisputeByCandidate = (id: number): iDispute | null => {
    let elem = null
    if (disputes.length) {
      disputes.map(item => {
        if (item.candidate_id === id) elem = item
      })
    }
    return elem
  }

  const getStatusLabel = (dispute: iDispute|undefined): string => {
    if (dispute?.status === STATUS_NEW) {
      return 'Pending review'
    }
    if (dispute?.status === STATUS_IN_PROGRESS) {
      return 'Reviewing'
    }
    if (dispute?.status === STATUS_DEPRECATED) {
      return 'Deprecated'
    }

    if (dispute?.status && [STATUS_CONFIRMED, STATUS_REJECTED].includes(dispute.status)) {
      return dispute?.is_matched_by_system ? 'Matched' : 'Not match'
    }

    if (disputes.length) {
      return 'Cand be add'
    }
    return 'New'
  }

  const getCandidateBgColor = (candidate: iEstate) => {
    const isMatchedBySystem = confirmed_duplicates?.includes(candidate.id)
    const isMatchedByUser = candidate.marked
    if (isMatchedBySystem === true && isMatchedByUser !== true) {
      return '#00800026'
    }
    if (isMatchedBySystem === false && isMatchedByUser === true) {
      return '#D32F2F26'
    }
  }

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const candidate_id = parseInt(event.target.name)
    setComment({ ...comments, [candidate_id]: event.target.value })
  }

  const handleUnMatch = async (candidate_id: number) => {
    const result = await updateEvaluationRequest(task_id, {
      confirmed_duplicates: confirmed_duplicates.filter(item => item !== candidate_id),
    })
    // @ts-ignore
    if (result) window.location.reload()
  }

  const handleMatch = async (candidate_id: number) => {
    const result = await updateEvaluationRequest(task_id, {
      confirmed_duplicates: [...confirmed_duplicates, candidate_id],
    })
    // @ts-ignore
    if (result) window.location.reload()
  }

  const renderCandidate = (candidate: iEstate, dispute: iDispute | undefined) => {
    const isMatchedBySystem = confirmed_duplicates?.includes(candidate.id)
    const isMatchedByUser = candidate.marked
    const renderIsMatchedIcon = (flag: boolean) => {
      if (flag) return <IconCheck fontSize="small" titleAccess="Matched" sx={{display: 'flex'}} />
      return <IconCross fontSize="small" titleAccess="Not matched" sx={{display: 'flex'}} />
    }

    return <Box id={`item-${candidate.id}`} key={`candidate-${candidate.id}`} sx={{
      marginBottom: '20px',
      backgroundColor: getCandidateBgColor(candidate),
    }}>
      <Candidate
        estate={candidate}
        renderControls={
          <ButtonGroup variant="outlined" size="small">
            {(is_reference_vote && allow_manage) && <Button color="error">Reference</Button>}
            <Button color="secondary">
              You: {renderIsMatchedIcon(isMatchedByUser)}
            </Button>
            <Button color="secondary">
                System: {renderIsMatchedIcon(isMatchedBySystem)}
            </Button>
            {(target || dispute?.status) && (
              <Button disabled={true} disableFocusRipple={true} className={`btn-status-${dispute?.status}`}>
                {getStatusLabel(dispute)}
              </Button>
            )}
            {(allow_manage && isMatchedBySystem) && <Button color="warning" onClick={() => handleUnMatch(candidate.id)}>Un match</Button>}
            {(allow_manage && !isMatchedBySystem) && <Button color="primary" onClick={() => handleMatch(candidate.id)}>Match</Button>}
          </ButtonGroup>
        }
        renderAfter={
          <Stack
            direction="row"
            spacing={2}
            sx={{ p: 1 }}
          >
            {(target || dispute?.status) && (
              <>
              <TextField
                name={`${candidate.id}-candidate`}
                label="Comment"
                multiline
                rows={1}
                placeholder="Image #3 looks like #1 from target estate, so I'm sure it's duplicate."
                value={dispute ? dispute.comment : comments[candidate.id] || ''}
                disabled={dispute ? true : false}
                onChange={handleCommentChange}
                fullWidth
              />
            </>)}
          </Stack>
        }
      />
    </Box>
  }

  if (!fetchingD && !fetchingV && !target) {
    return (
      <Stack sx={{ width: '100%' }} spacing={2}>
        <Alert severity="error">
          <AlertTitle>Deprecated.</AlertTitle>
          Target listing or candidates have already removed or merged.
        </Alert>
      </Stack>
    )
  }

  return (
    <Box width="100%">
      <Box sx={{
        backgroundColor: '#fff',
        marginBottom: '20px',
        position: 'sticky',
        zIndex: 5,
        boxShadow: 10,
        top: 64,
      }}>
        <Candidate
          estate={target}
          renderControls={
            <ButtonGroup variant="outlined" size="small">
              {disputes.length < candidates.length &&
                <Button
                  color="success"
                  disabled={isEmpty(comments)}
                  title={isEmpty(comments) ? 'You should leave at least one comment' : ''}
                  onClick={openDisputes}
                >
                  {disputes.length ? 'Add to dispute' : 'Open dispute'}
                </Button>
              }
            </ButtonGroup>
          }
        />
      </Box>

      {!!candidates?.length && candidates.map((candidate, index) => {
        const dispute = getDisputeByCandidate(candidate.id)
        if (dispute) {
          return renderCandidate(candidate, dispute)
        }
      })}
      {!!candidates?.length && candidates.map((candidate, index) => {
        const dispute = getDisputeByCandidate(candidate.id)
        if (!dispute) {
          return renderCandidate(candidate, undefined)
        }
      })}

      {!!disputes?.length && disputes.map(item => {
        // Search removed listing
        const isRemoved = !(candidates?.length && candidates?.find(candidate => candidate.id === item.candidate_id))
        if (isRemoved) return (
          <Alert key={`removed-${item.candidate_id}`}>
            <AlertTitle>Candidate listing has already removed or merged.</AlertTitle>
            Status: {getStatusLabel(item)}
          </Alert>
        )
      })}
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    count: state.dispute.count,
    disputes: state.dispute.list,
    fetchingD: state.dispute.fetching,

    fetchingV: state.voting.fetching,
    target: state.voting.target,
    candidates: state.voting.candidates,
    confirmed_duplicates: state.voting.confirmed_duplicates,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createDispute: (data: iDispute) => dispatch(DisputeActions.createDispute(data)),
    loadDisputes: (filter: GridFilterModel) => dispatch(DisputeActions.listDispute(0, 30, null, filter)),
    loadVoteById: (id: number) => dispatch(VotingActions.loadById(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DisputePage)
