import React from 'react'
import { Button, ButtonGroup, Tooltip } from '@mui/material'
import VotingActions from '../../redux/votingRedux'
import { connect } from 'react-redux'

interface iProps {
  id: number
  isMarked: boolean
  fetching: boolean
  candidate_duplicates: number[]

  vote: any
  parent_check_of_send_candidate_duplicates: number

  skip: () => void
  finish: () => void
  goBack: () => void

  addMatch: (id: number) => void,
  removeMatch: (id: number) => void,
  addCandidateDuplicate: (id: number) => void
}

function Controls(props: iProps) {
  const { id, isMarked, fetching, candidate_duplicates } = props

  const renderDuplicatedBtn = () => {
    if (candidate_duplicates.includes(id)) {
      return <Button
        disabled={true}
        color="warning"
      >Already added</Button>
    }
    return (
      <Tooltip title="Temporarily not available">
        <span>
          <Button
            disabled
            color="warning"
            // onClick={() => props.addCandidateDuplicate(id)}
          >Mark as duplicate</Button>
        </span>
      </Tooltip>
    )
  }

  if (isMarked) {
    return (
      <ButtonGroup variant="contained" size="small" disabled={fetching}>
        <Button
          color="error"
          onClick={() => props.removeMatch(id)}
        >Exclude</Button>
      </ButtonGroup>
    )
  }
  return (
    <ButtonGroup variant="contained" size="small" disabled={fetching || candidate_duplicates.includes(id)}>
      {renderDuplicatedBtn()}
      <Button
        color="info"
        onClick={() => props.addMatch(id)}
      >Select for match</Button>
    </ButtonGroup>
  )
}

const mapStateToProps = (state: any) => {
  return {
    fetching: state.voting.fetching,
    candidate_duplicates: state.voting.candidate_duplicates,

    vote: state.voting.vote,
    parent_check_of_send_candidate_duplicates: state.voting.parent_check_of_send_candidate_duplicates,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    finish: () => dispatch(VotingActions.finish()),
    skip: () => dispatch(VotingActions.skip()),
    goBack: () => dispatch(VotingActions.goBack()),

    addMatch: (id: number) => dispatch(VotingActions.addMatch(id)),
    removeMatch: (id: number) => dispatch(VotingActions.removeMatch(id)),
    addCandidateDuplicate: (id: number) => dispatch(VotingActions.addCandidateDuplicate(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)
