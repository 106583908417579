import React from 'react'
import Images from './Images'
import Info from './Info'
import Controls from './Controls'
import Statistic from './Statistic'
import SourceControls from './SourceControls'
import { Box, Stack, useTheme } from '@mui/material'
import { iEstate } from './Candidate'

export interface iProps {
  isSource: boolean
  estate: iEstate
  isDuplicate?: boolean
}

export default function Item(props: iProps) {
  const { isSource, estate, isDuplicate } = props
  const theme = useTheme()

  let options = {
    backgroundColor: theme.palette.secondary.light,
    marginBottom: '20px',
  }

  if (estate.marked) options.backgroundColor = '#00800026'
  if (isDuplicate) options.backgroundColor = '#ED6C0226'

  if (isSource) {
    const sourceOptions = {
      position: 'sticky',
      backgroundColor: 'white',
      zIndex: 5,
      boxShadow: 10,
      top: 64,
    }
    options = { ...options, ...sourceOptions }
  }

  // @ts-ignore
  const renderStatistic = isSource && <Statistic />

  return (
    <Box id={`item-${estate.id}`} sx={options}>
      {renderStatistic}
      <Images items={estate.pictures} />
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="baseline"
        spacing={2}
      >
        <Info meta={{
          id: estate.id,
          type: estate.meta.type_name,
          location: estate.meta.location,
          country: estate?.meta?.country,
          price: estate.meta.price,
          bedrooms: estate.meta.bedrooms,
          bathrooms: estate.meta.bathrooms,
          living_area: estate?.meta?.living_area,
          plot_area: estate?.meta?.plot_area,
          total_area: estate?.meta?.total_area,
        }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
              m: 1,
            },
          }}
        >
          {isSource ? (<SourceControls />) : (<Controls id={estate.id} isMarked={estate.marked} />)}
        </Box>
      </Stack>
    </Box>
  )
}
