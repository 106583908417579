import { connect } from 'react-redux'

import {
  Box,
  Button,
  ButtonGroup,
  Skeleton,
  Stack,
  Tooltip,
} from '@mui/material'
import {
  Euro as IconEuro,
  Bed as IconBedrooms,
  Shower as IconBathrooms,
  PhotoSizeSelectSmallSharp as IconLivingArea,
  SelectAll as IconTotalArea,
} from '@mui/icons-material'

import { DASH, METER_SQUARE } from '../../utils/constants'

export interface iCountry {
  id: number
  code: string
  name: string
}

export interface iMeta {
  id?: number
  type?: string
  location?: string
  country?: iCountry
  price?: number
  bedrooms?: number
  bathrooms?: number
  living_area?: number
  plot_area?: number
  total_area?: number
}

interface iProps {
  meta?: iMeta
  user: any
}

function Info(props: iProps) {
  const { meta, user } = props
  const isShowEstateId = user.permissions.allow_dispute && user.permissions.allow_manage

  return (
    <Box
      className="estate-info"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        '& > *': {
          m: 1,
        },
      }}
    >
      {meta ? (
        <ButtonGroup variant="outlined" size="small" color="secondary" disableRipple={true} disableFocusRipple={true}>
          {isShowEstateId && <Button># {meta?.id}</Button>}
          <Button>{meta.type || DASH}</Button>
          {meta?.country && (
            <Button title={meta.country.name}>{meta.country.code || DASH}</Button>
          )}
          <Button>{meta.location || DASH}</Button>
          <Button>
            {meta.price || DASH}
            <IconEuro fontSize="small" />
          </Button>
          <Tooltip title="Bedrooms">
            <Button>
              {meta.bedrooms || DASH}
              <IconBedrooms fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Bathrooms">
            <Button>
              {meta.bathrooms || DASH}
              <IconBathrooms fontSize="small" />
            </Button>
          </Tooltip>
          <Tooltip title="Living area">
            <Button>
              <IconLivingArea fontSize="small" />
              {meta.living_area ? `${meta.living_area}${METER_SQUARE}` : DASH}
            </Button>
          </Tooltip>
          <Tooltip title="Plot area">
            <Button>
              <IconLivingArea fontSize="small" enableBackground="black" color="secondary" />
              {meta.plot_area ? `${meta.plot_area}${METER_SQUARE}` : DASH}
            </Button>
          </Tooltip>
          <Tooltip title="Total area">
            <Button>
              <IconTotalArea fontSize="small" />
              {meta.total_area ? `${meta.total_area}${METER_SQUARE}` : DASH}
            </Button>
          </Tooltip>
          { user.permissions.allow_manage && (
            <Button
              onClick={() => {
                const url = `http://tools.espressoresearch.com/_admin/sourceestate/${meta.id}`
                // @ts-ignore
                window.open(url, '_blank').focus()
              }}
              title="Go to the tools"
            >Casafari</Button>
          )}
        </ButtonGroup>
      ) : (
        <Stack direction="row" spacing={2}>
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
          <Skeleton variant="rectangular" width={80} height={30} />
        </Stack>
      )}
    </Box>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps, {})(Info)
