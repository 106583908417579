import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { shallow } from 'zustand/shallow'
import {
  IconButton,
  Badge,
  Menu,
} from '@mui/material'
import { Mail as MailIcon } from '@mui/icons-material'

import { iUser } from '../../redux/authRedux'
import { UseMessageStore } from '../../stores/UseMessageStore'
import AnnotatorChat from '../widgets/chat/AnnotatorChat'
import ManagerChat from '../widgets/chat/ManagerChat'

interface iProps {
  user: iUser
}

function MessageDropDown({ user }: iProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const { unread_messages, loadCounter } = UseMessageStore((state) => ({
    unread_messages: state.unread_messages,
    loadCounter: state.loadCounter,
  }), shallow)

  useEffect(() => {
    loadCounter()
    const intervalId = setInterval(() => {
      loadCounter()
    }, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [loadCounter])

  const handleMessageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const handleMessageMenuClose = () => {
    setAnchorEl(null)
  }

  const isHideBadge = unread_messages === 0

  return (
    <>
      <IconButton
        aria-label={unread_messages ? `There are ${unread_messages} new messages` : 'Any new messages'}
        aria-controls="menu-message"
        aria-haspopup="true"
        onClick={handleMessageMenu}
        color="inherit"
      >
        <Badge badgeContent={unread_messages} color="error" invisible={isHideBadge}>
          <MailIcon />
        </Badge>
      </IconButton>
      <Menu
        id="menu-message"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={openMenu}
        onClose={handleMessageMenuClose}
      >
        {openMenu && (
          user.permissions.allow_users ? <ManagerChat /> : <AnnotatorChat />
        )}
      </Menu>
    </>
  )
}

const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(MessageDropDown)
