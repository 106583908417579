import { useMemo } from 'react'
import { ThemeProvider, createTheme } from '@mui/material/styles'
import { Box, Grid, Typography, Slider, Tooltip, IconButton } from '@mui/material'
import { green, pink, yellow } from '@mui/material/colors'
import { classNames } from '../../utils/functions'
import { Help } from '@mui/icons-material'

interface iProps {
  value: number|undefined,
}

const themePink = createTheme({
  palette: {
    primary: {
      main: pink[200]
    }
  }
})

const themeYellow = createTheme({
  palette: {
    primary: {
      main: yellow[200]
    }
  }
})

const themeGreen = createTheme({
  palette: {
    primary: {
      main: green[200]
    }
  }
})

export function QualityBonusBooster(props: iProps) {
  const MIN = 0
  const MAX = 100

  return useMemo(() => {
    let value = props.value
    if (value === undefined || value < MIN) value = MIN
    if (value > MAX) value = MAX

    return (
      <Box>
        <Typography
          component="h2"
          variant="h6"
          color="primary"
          gutterBottom
          sx={{display: "flex", justifyContent: "space-between"}}
        >
          Quality bonus booster
          <Tooltip title={(<>
            <div>Rate per vote, details by average resolution:</div>
            <div>0-60% - bad quality, multiplier of 80%</div>
            <div>60-80% - good quality, multiplier of 100%</div>
            <div>80-100% - very good quality, multiplier of 115%</div>
          </>)}>
            <IconButton>
              <Help/>
            </IconButton>
          </Tooltip>
        </Typography>
        <Grid container spacing={0} columns={100} alignItems="center">
          <Grid item xs={60}>
            <ThemeProvider theme={themePink}>
              <Slider
                value={value}
                min={0}
                max={60}
                marks={[
                  {
                    value: 30,
                    label: '80%',
                  }
                ]}
                className={classNames({
                  "slider-hide-thumb": value >= 60,
                  "slider-active-markLabel": value <= 60,
                })}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={20}>
            <ThemeProvider theme={themeYellow}>
              <Slider
                value={value}
                min={61}
                max={80}
                marks={[
                  {
                    value: 70,
                    label: '100%',
                  }
                ]}
                className={classNames({
                  "slider-hide-thumb": value < 61 || value > 80,
                  "slider-active-markLabel": value >= 61 && value <= 80,
                  "slider-hide-track": value < 80,
                })}
              />
            </ThemeProvider>
          </Grid>
          <Grid item xs={20}>
            <ThemeProvider theme={themeGreen}>
              <Slider
                value={value}
                min={81}
                max={100}
                marks={[
                  {
                    value: 90,
                    label: '115%',
                  }
                ]}
                className={classNames({
                  "slider-hide-thumb": value < 81,
                  "slider-active-markLabel": value >= 81,
                  "slider-hide-track": value < 81,
                })}
              />
            </ThemeProvider>
          </Grid>
        </Grid>
      </Box>
    )
  }, [props.value])
}

const themeGreen1 = createTheme({
  palette: {
    primary: {
      main: green[100]
    }
  }
})
const themeGreen2 = createTheme({
  palette: {
    primary: {
      main: green[200]
    }
  }
})
const themeGreen3 = createTheme({
  palette: {
    primary: {
      main: green[300]
    }
  }
})
const themeGreen4 = createTheme({
  palette: {
    primary: {
      main: green[400]
    }
  }
})
const themeGreen5 = createTheme({
  palette: {
    primary: {
      main: green[500]
    }
  }
})

export function QuantityBonusBooster(props: iProps) {
  const MIN = 0
  const MAX = 500
  const value = (!props.value || props.value < MIN) ? MIN : (props.value > MAX) ? MAX : props.value;

  return useMemo(() => (
    <Box>
      <Typography
        component="h2"
        variant="h6"
        color="primary"
        gutterBottom
        sx={{display: "flex", justifyContent: "space-between"}}
      >
        Quantity bonus booster
        <Tooltip title={(<>
          <div>Bonus over rate, details:</div>
          <div>First 200 - without bonus</div>
          <div>201-400 - with bonus 10%</div>
          <div>401-600 - with bonus 15%</div>
          <div>601-800 - with bonus 20%</div>
          <div>801+ - with bonus 25%</div>
          <div>*Can be disabled in case of poor quality of work</div>
        </>)}>
          <IconButton>
            <Help/>
          </IconButton>
        </Tooltip>
      </Typography>
      <Grid container spacing={0} alignItems="center">
        <Grid item xs>
          <ThemeProvider theme={themeGreen1}>
            <Slider
              value={value}
              min={0}
              max={200}
              marks={[
                {
                  value: 100,
                  label: '100%',
                }
              ]}
              className={classNames({
                "slider-hide-thumb": value > 200,
                "slider-active-markLabel": value <= 200,
              })}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs>
          <ThemeProvider theme={themeGreen2}>
            <Slider
              value={value}
              min={201}
              max={400}
              marks={[
                {
                  value: 300,
                  label: '110%',
                }
              ]}
              className={classNames({
                "slider-hide-thumb": value < 201 || value > 400,
                "slider-active-markLabel": value >= 201 && value <= 400,
                "slider-hide-track": value < 201,
              })}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs>
          <ThemeProvider theme={themeGreen3}>
            <Slider
              value={value}
              min={401}
              max={600}
              marks={[
                {
                  value: 500,
                  label: '115%',
                }
              ]}
              className={classNames({
                "slider-hide-thumb": value < 401 || value > 600,
                "slider-active-markLabel": value >= 401 && value <= 600,
                "slider-hide-track": value < 401,
              })}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs>
          <ThemeProvider theme={themeGreen4}>
            <Slider
              value={value}
              min={601}
              max={800}
              marks={[
                {
                  value: 700,
                  label: '120%',
                }
              ]}
              className={classNames({
                "slider-hide-thumb": value < 601 || value > 800,
                "slider-active-markLabel": value >= 601 && value <= 800,
                "slider-hide-track": value < 601,
              })}
            />
          </ThemeProvider>
        </Grid>
        <Grid item xs>
          <ThemeProvider theme={themeGreen5}>
            <Slider
              value={value}
              min={801}
              max={1000}
              marks={[
                {
                  value: 900,
                  label: '125%',
                }
              ]}
              className={classNames({
                "slider-hide-thumb": value < 401,
                "slider-active-markLabel": value >= 401,
                "slider-hide-track": value < 401,
              })}
            />
          </ThemeProvider>
        </Grid>
      </Grid>
    </Box>
  ), [value])
}
