import React, { useEffect, useMemo, useRef } from 'react'
import { connect } from 'react-redux'
import { shallow } from 'zustand/shallow'
import {
  Alert,
  AlertTitle,
  IconButton,
  LinearProgress,
  List,
  ListItem,
} from '@mui/material'
import { UndoOutlined as IconBack } from '@mui/icons-material'

import { iUser } from '../../../redux/authRedux'
import { iMessage, UseMessageStore } from '../../../stores/UseMessageStore'
import CreateMessageInline from './CreateMessageInline'
import Message from './Message'


interface iProps {
  chatWithID?: number
  onClickBack?: () => void
  user: iUser
}

function AnnotatorChat({ chatWithID, onClickBack, user }: iProps) {
  const lastElementRef: any = useRef()
  const { messages, loadMessages, loading } = UseMessageStore((state) => ({
    messages: state.messages,
    loadMessages: state.loadMessages,
    loading: state.loading,
  }), shallow)

  useEffect(() => {
    loadMessages(chatWithID)
    const intervalId = setInterval(() => {
      loadMessages(chatWithID)
    }, 60 * 1000)

    return () => clearInterval(intervalId)
  }, [chatWithID, loadMessages])

  useEffect(() => {
    if (lastElementRef.current) lastElementRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [messages])

  const rightSenderId = useMemo(() => {
    let mark_sender_id = 0
    messages.forEach((message) => {
      if (message.sender?.id === user.id) mark_sender_id = user.id
    })
    if (!mark_sender_id) {
      if (messages.length && messages[0].recipient?.id) mark_sender_id = messages[0].sender?.id || 0
      if (messages.length > 1 && messages[1].sender?.id) mark_sender_id = messages[1].sender?.id || 0
    }

    return mark_sender_id
  }, [user.id, messages])

  return <>
    {onClickBack && <IconButton aria-label="back" onClick={onClickBack}>
        <IconBack />
    </IconButton>}
    {messages.length === 0 && (
      <Alert severity="info">
        <AlertTitle>There are any messages</AlertTitle>
        You can create first one if have any questions...
      </Alert>
    )}
    <List sx={{ width: 360, maxHeight: 500, overflow: 'auto', bgcolor: 'background.paper' }}>
      {messages.map((message: iMessage) =>
        <Message
          key={message.id}
          message={message}
          side={rightSenderId === message.sender?.id ? 'right' : 'left'}
        />
      )}
      <ListItem ref={lastElementRef} />
    </List>

    {loading && <LinearProgress />}
    <CreateMessageInline recipient={chatWithID} />
  </>
}


const mapStateToProps = (state: any) => {
  return {
    user: state.auth.user,
  }
}

export default connect(mapStateToProps)(AnnotatorChat)
